.main-content,
.sidebar-container {
    transition: .5s ease-in-out;
}

.tooltip-display {
    position: absolute;
    background: #fff;
    left: 70%;
    padding: 5px;
    display: none;
}
.side_menuitem:hover .tooltip-display{
    display: block;
}