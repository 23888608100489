
.float-right {
    float: right;
  }
  .float-left {
    float: left;
  }
  .formbuilder button {
    border: none;
    padding: 8px;
    margin-right: 2px;
  }
  .blue {
    background-color: blue;
    color: white;
    border: none;
    padding: 8px;
  }
  .green {
    background-color: darkolivegreen;
    color: white;
  }
  .btn-toolbar {
    padding-bottom: 10px;
    /* margin: auto; */
  }