// background-color
.bg-main {
    background: $main !important;
}

.bg-green1 {
    background: #14b8a6 !important;
}

.bg-white-color {
    background: $white;
}
.bg-yellow {
    background: $yellow;
}

.bg-dark {
    background: $dark;
}
.bg-light-dark {
    background: $ligthdark !important;
}
.bg-light-green {
    background: $light-green;
}
.bg-purple {
    background: $purple !important;
}
.lightest-green {
    background: $lightest-green;
}
.bg-light-blue {
    background: $light-blue;
}
.bg-goldan {
    background: $goldan;
}
.bg-light-main {
    background: $light-main;
}
.bg-gray-dark {
    background: $graydark !important;
}
.bg-gray-light {
    background: $gray-light !important;
}
.bg-white-transparent {
    background: $whitetransparent;
}
.bg-red {
    background: $red !important;
}
.bg-pink {
    background: $pink;
}
.bg-whitesmoke {
    background: $whitesmoke !important;
}
.bg-greenshade {
    background: $greenshade;
}
.bg-lightred {
    background: $light-red !important;
}

// text-color
.text-greendark {
    color: $darkgreen;
}
.text-dark {
    color: $dark !important;
}
.white-smoke {
    color: $whitesmoke !important;
}
.text-gray-color {
    color: $gray-color;
}
.text-graylight {
    color: $gray-light;
}
.text-graylight-color {
    color: #b0b0b0;
}
.text-gray {
    color: $gray;
}
.text-red {
    color: $red;
}
.text-green {
    color: $green;
}

.text-dark-purple {
    color: $drkpurple;
}

.text-light-green {
    color: $light-green;
}

.text-main {
    color: $main;
}

.text-dark-gray {
    color: $graydark;
}
.text-blue {
    color: $blue !important;
}
.text-black {
    color: $black !important;
}
// border
.border-light {
    border: 1px solid $main !important;
}
.main-content {
    height: 92vh;
    overflow: scroll;
}
// button hover
.ripple {
    position: relative;
    overflow: hidden;
}
.ripple::before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    width: 10%;
    height: 25%;
    border-radius: 50%;
    z-index: 1;
    top: 35%;
    left: 45%;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.ripple:hover::before {
    -webkit-transform: scale(12);
    transform: scale(12);
    -webkit-transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    transition: transform 0.5s, border-radius 0.5s 0.5s;
    transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
}

.ripple:hover::after {
    content: "";
    position: absolute;
    z-index: 2;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.search-intrument .p-overlaypanel-content {
    padding: 0 !important;
}
.assign-student.p-dialog .p-dialog-header {
    color: #000 !important;
}
.class-type.p-dropdown .p-dropdown-label {
    display: none;
}
.class-type.p-dropdown.p-focus {
    outline: none !important;
    box-shadow: none !important;
}
.w-400 {
    width: 400px;
}

.mr-15 {
    margin-right: 15px !important;
}

.notification-div {
    display: grid;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
}
