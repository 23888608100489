// @import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600;1,700&family=Open+Sans:wght@300&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Lora:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600;1,700&family=Open+Sans:wght@300&display=swap");
@import "./assets/sass/all.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

* {
    margin: 0;
    padding: 0;
}

// icon color
.p-rating-icon {
    color: #f8a326 !important;
}
// loader
.loader {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    animation: moveLoader 1s linear infinite;
    border-color: #bcddfc #5f9dfa #0084ff #a6d1fa;
    margin-left: 4px;
}

@keyframes moveLoader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

::-webkit-scrollbar {
    // display: none;
}

.notification-div {
    max-height: 350px;
    overflow-y: auto;
}
.cross-sign {
    right: 10px;
}
body {
    font-family: "Manrope" !important;
}

.open-sans {
    font-family: "Manrope" !important;
}

.p-card .p-card-content {
    padding: 0 !important;
}

.pointer {
    cursor: pointer;
}

.p-carousel.p-component.p-carousel-horizontal {
    position: relative;
}

ul.p-carousel-indicators.p-reset {
    position: absolute;
    right: 27%;
    bottom: 15%;
}

.lesson-type label {
    color: #000 !important;
    font-size: 14px !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background: #e3f2fd;
    color: #495057;
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 50% !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #2196f3;
    border: 3px solid #e9ecef;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    display: none;
}

// login
.dashboard-login input,
.dashboard-login .p-password-input {
    border-left: 0px !important;
    background: #fff !important;
}

// dashboard card
.white-smoke.dashboard_card {
    background: $whitesmoke;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 10px;
}

.p-datatable .pi-sort-alt:before {
    content: "";
    background-image: url("./assets/icons/sortable.png") !important;
    height: 15px;
    width: 15px;
    position: absolute;
    background-repeat: no-repeat;
    top: 40%;
    background-size: contain;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.3 !important;
}

.calender_picker .p-button {
    background: none !important;
    border: 0;
}

.calender_picker input {
    border: 0;
    padding-left: 40px;
}

.calender_picker::after {
    content: "";
    position: absolute;
    background: url(./assets/icons/uim_calender.png);
    background-size: contain;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 12px;
}
// .p-radiobutton-box.p-highlight .p-radiobutton-icon{
//   visibility: hidden !important;
// }
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #14b8a6 !important;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
    background: none !important;
}
.p-dropdown.category-select .p-dropdown-label.p-placeholder,
.category-select {
    color: #000 !important;
}

.calender_picker.p-calendar.p-component.p-inputwrapper.w-full.border-round-lg.overflow-hidden.border-1.border-400.p-calendar-w-btn.p-calendar-w-btn-right {
    flex-direction: row-reverse;
}

// forms feild input
input:focus-visible,
input:focus,
textarea:focus,
Button,
.p-radiobutton-box {
    outline: none !important;
    box-shadow: none !important;
}

input::placeholder,
input,
select,
.p-dropdown .p-dropdown-label {
    font-family: "Manrope" !important;
}
.fc-event-title {
    white-space: normal;
}

// switch
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $main !important;
}

.p-calendar .p-button {
    border-radius: 6px 0 0 6px !important;
}

//  menus
.p-menu.p-menu-overlay {
    font-family: "Manrope" !important;
    font-size: 0.874rem;
    min-width: 200px;
}
.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled):hover,
.p-dropdown,
.p-multiselect {
    border-color: #d8dde1 !important;
    outline: none !important;
    box-shadow: none !important;
}
.teacher-chips .p-chips .p-inputtext {
    width: 100%;
    border: none !important;
    display: block !important;
}
// shadow
.show-inactive.active {
    // box-shadow: inset 1px 1px 10px gray;
    background-color: rgb(231, 229, 229);
}
// Responsive
@media screen and (max-width: 567px) {
    .p-8.padding-none {
        padding: 1rem !important;
    }
}

p {
    margin-top: 0;
    margin-bottom: 0px !important;
}

.p-button {
    color: #000000 !important;
    border-radius: 5px !important;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.form-label {
    top: -9px;
    left: 10px;
    padding: 0 5px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    height: 36px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    height: 39px !important;
}

.sidemenu.active {
    width: 80px;
}

.radio {
    width: 15px;
    height: 15px;
}

.checkbox {
    width: 20px;
    height: 20px;
}

textarea:focus,
input:focus,
input:focus,
.p-inputgroup:focus select:focus,
time:focus,
button:focus {
    outline: none;
}

.password,
.editor,
.time,
.calender {
    overflow: hidden;
}

.teacher-list thead.p-datatable-thead tr th {
    background: #fff !important;
    color: #59c6bc !important;
    font-weight: 700 !important;
}

thead.p-datatable-thead tr th {
    background: #59c6bc !important;
    color: #000 !important;
    font-weight: 700 !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: #59c6bc1a !important;
}

.file-upload .p-button {
    border: 0;
    background: #59c6bc !important;
    border-radius: 5px !important;
    color: #fff !important;
}
.p-component-overlay {
    background: rgba(0, 0, 0, 0.3) !important;
}

.fit-cover {
    object-fit: cover;
    object-position: center;
}
.p-inputtext {
    padding: 0.55rem 0.75rem !important;
}

.user-profile {
    width: 100%;
    height: 325px;
}
.p-dropdown.group-dropdown .p-dropdown-label.p-placeholder {
    color: #000;
}
// calendar event font size
.fc-event-title.fc-sticky {
    font-size: 12px;
    font-weight: 600;
    padding: 7px;
}
.scrollbar-show::-webkit-scrollbar {
    max-height: 150px;
    // height: 100% !important;
    display: block;
    width: 10px !important;
}
.scrollbar-show::-webkit-scrollbar-thumb {
    background-color: rgb(206, 205, 205);
    border-right: none;
    border-radius: 10px;
    border-left: none;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: $main !important;
    color: #fff !important;
}
.icon-rotate img {
    transform: rotate(180deg);
}
.MuiPaper-root {
    position: relative !important;
    z-index: 7 !important;
}
span.MuiTypography-root {
    display: flex;
    align-items: center;
}
li.MuiButtonBase-root:hover {
    background: $light-main;
}
// instrument
.search-intrument.p-overlaypanel:after,
.search-intrument.p-overlaypanel:before {
    display: none;
}

.programme-selected {
    border: 1px solid $main;
}
.card-svg {
    top: 32%;
    right: 20px;
    width: 40px;
}
.template-dropdown {
    max-height: 300px;
    overflow: auto;
    width: 400px !important;
}
.p-inplace .p-inplace-display {
    padding: 0.18rem 0.25rem !important;
    border-radius: 6px !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}
.p-inplace .p-inplace-display {
    display: inline !important;
    cursor: pointer !important;
    font-size: 12px !important;
    color: #14b8a6;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #14b8a6 !important;
    color: #ffffff !important;
}
.gjs-two-color {
    color: #fff;
    background-color: "gray";
}
// invoice
.invoice-btn-index {
    z-index: 999999;
}
.react-multi-chips {
    .css-13cymwt-control,
    .css-t3ipsp-control {
        border: none;
        padding: 0.25rem 0.75rem !important;
        border-radius: 10px;
        border-color: #bdbdbd;
        box-shadow: none;
    }
    .css-1nmdiq5-menu {
        z-index: 2;
    }
}


//lexical editor
.editor-text-bold {
    font-weight: bold;
  }
  
  .editor-text-italic {
    font-style: italic;
  }
  
  .editor-text-strikethrough {
    text-decoration: line-through;
  }
  
  .editor-text-underline {
    text-decoration: underline;
  }
  
  .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }
  
  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }
  
  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
  
  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
  
  .editor-listitem {
    margin: 8px 32px 8px 32px;
  }
  
  .editor-nested-listitem {
    list-style-type: none;
  }
  
  .editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
  }
  
  .editor-text-code {
    background-color: #f0f2f5;
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }
  
  .editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
  }
  
  .other ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .link-editor .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }
  
  .link-editor div.link-edit {
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }
  
  .link-editor .link-input a {
    color: rgb(33, 111, 219);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
  }
  
  .link-editor .link-input a:hover {
    text-decoration: underline;
  }
  
  .link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
  }
  
  .link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
  }