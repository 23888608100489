.avtar {
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #000;
    line-height: 18px;
}
.side_menuitem.active{
    background: linear-gradient(90.16deg, #59C6BC -0.84%, rgba(89, 198, 188, 0) 98.91%);
    border-left:3px solid #363636;
    font-weight: 700;
}
thead.p-datatable-thead tr th{
    background: $main !important;
}
.p-datatable-table tr th, .p-datatable-table tr td{
    font-family: 'Manrope' !important;
}
.p-dialog-header{
    background: $main !important;
    padding: 1rem !important;
    color: #000 !important;
    font-family: 'Manrope' !important;
    text-align: center;
}
.p-dialog .p-dialog-header-icons, .passowrd-change .p-dialog-header{
    // display: none !important;
}
.p-dialog .p-dialog-content{
    padding: 1rem !important;
}
p.disc-list::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
    left: 15px;
    top: 25px;
}
.file-upload .p-button{
    border: 0;
    background: $light-green !important;
}