.login{
    background: url(../../images/banner.png);
    position: relative;
    background-size: cover;
    background-position: center;
}
.login::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: -0;
}
input.p-inputtext.p-component.p-password-input{
    border: 0 !important;
    width: 100%;
}
input::placeholder{
    font-size: .85rem;
font-family: 'open-sans';
}
.text-white-border {
    color:transparent !important;
    -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
}

@media screen and (max-width:567px){
    .login-banner{
        height: auto !important;
    }
}