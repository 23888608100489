$main: #59c6bc;
$black: #000;
$light-main: rgb(89, 198, 188, 0.1);
$yellow: #f3e96e;
$dark: #363636;
$ligthdark: rgb(54, 54, 54, 0.1);
$light-green: rgba(89, 198, 188, 0.2);
$lightest-green: rgba(89, 198, 188, 0.3);
$goldan: #eead0e;
$graydark: #667085;
$drkpurple: #312e49;
$green: #00c838;
$red: #e20000;
$light-red: #fd5a5a;
$gray: #2e353b;
$pink: #d445f8;
$blue: #0085ff;
$whitesmoke: #f5f5f5;
$gray-light: #e8e6e6;
$white: #fff;
$light-blue: #a5e6ff;
$darkgreen: #134000;
$greenshade: #dff0d8;
$whitetransparent: rgba(255, 255, 255, 0.5);
$gray-color: rgb(0, 0, 0, 50);
$purple: #9564e2;
