.column-menuitem.p-menu .p-menuitem-link .p-menuitem-text{
    margin-left: 10px !important;
}
.upload-file .p-button{
    border: none ;
}
.upload-file .p-button .p-button-icon-left{
    display: none;
}
.upload-file .p-button{
    background: $main !important;
}
.multichips.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #59C6BC;
    color: #495057;
    border-radius: 20px;
}