.history-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link, .history-accordion.p-accordion .p-accordion-header .p-accordion-header-link{
    background: none !important;
    text-decoration: none;
    box-shadow: none !important;
}
.history-accordion.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    right: 10px;
    position: absolute;
}
.chatting_msg::before {
    position: absolute;
    content: '';
    left: -30px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 30px solid #D9D9D9;
    border-bottom: 15px solid transparent;
}
.user-tab.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    box-shadow: none !important;
    background: $lightest-green;
    color: $black;
}